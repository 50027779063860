/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/***********************************************
 * Make clicks on mobile devices smoother
 ***********************************************/
jQuery(document).ready(function () {
    if(typeof FastClick === 'function') {
        FastClick.attach(document.body);
    }
});


/***********************************************
 * Disable hover effects when page is scrolled
 ***********************************************/
(function () {
    var body = document.body,
        timer;

    window.addEventListener('scroll', function () {
        clearTimeout(timer);
        if (!body.classList.contains('disable-hover')) {
            body.classList.add('disable-hover')
        }

        timer = setTimeout(function () {
            body.classList.remove('disable-hover')
        }, 100);
    }, false);
})();